
dialog{
  min-width: 30vw;
  border: 1px solid var(--color-element);
  border-radius: 5px;
  background-color: var(--color-background);
  color: var(--color-text);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12),0 1px 2px rgba(0,0,0,0.24);
  //This is to allow using an onclick handler to close the modal:
  // When the click is on a child of the dialog, we don't close it.
  // But it requires a padding of 0. So we forward the padding to the dialog's children
  padding: 0;
  > * {
    padding: 10px;
    &:not(:first-child){
      padding-top: 0;
    }
    &:not(:last-child){
      padding-bottom: 0;
    }
  }
  
  &::backdrop{
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(1px) grayscale(0.6);
  }

  >button#exit {
    position: absolute;
    top:10px;
    right:10px;
    width: 20px;
    height: 20px;
    padding:0;
    cursor: pointer;
    svg{
      fill: var(--color-secondary);
      width:100%;
      height: 100%;
    }
  }
  
  .dialog-buttons{
    display: flex;
    justify-content: end;
    gap: 4px;
    margin-top: 8px;
  }

}
